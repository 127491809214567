"use client";

import { FlexColumn } from "@/components/Flex";
import Mountable from "@/components/styled/div/Mountable";
import LinkWrapper from "@/components/wrappers/Link";
import { themeColors } from "@/utils/themeColors";
import { SubscriptionType, UpgradeEvent } from "@knowt/syncing/graphql/schema";
import { LocalUser } from "@knowt/syncing/hooks/user/types";
import { useCurrentUser } from "@knowt/syncing/hooks/user/useCurrentUser";
import {
    BookCheck,
    Bookmark,
    Gift,
    House,
    Presentation,
    Library,
    School,
    Settings,
    ShoppingBag,
    Telescope,
    Trash2,
    Zap,
    Shield,
} from "lucide-react";
import { usePathname } from "next/navigation";
import { useCallback, useEffect, useLayoutEffect, useState } from "react";
import SidebarDivider from "./components/styled/SidebarDivider";
import { SidebarItemBtn } from "./components/styled/SidebarItemBtn";
import SidebarSection from "./components/styled/SidebarSection";
import {
    SidebarTab,
    getSidebarHighlight as getSidebarHighlight_,
    getSidebarImage,
    getSidebarTabHref as getSidebarTabHref_,
} from "./utils";
import ProfileCircleWithMenu from "@/components/ProfileCircleWithMenu";
import { useBreakPoints } from "@/hooks/styles/useBreakpoints";
import br from "@/styles/breakpoints.module.css";
import clsx from "clsx";
import KnowtLogo from "./components/KnowtLogo";
import SidebarToggler from "./components/SidebarToggler";
import { spacing } from "@/utils/spacing";
import { updateUserInfo } from "@knowt/syncing/hooks/user/utils";
import { useDebouncedCallback } from "@knowt/syncing/utils/hooks/useDebouncedCallback";
import Image from "next/image";
import { useFlow } from "pointer-sdk";
import STUDENT_ONBOARDING from "@/components/pointer/STUDENT_ONBOARDING";
import TEACHER_ONBOARDING from "@/components/pointer/TEACHER_ONBOARDING";
import { useUpgradePopupContextSelector } from "@/features/Payments/PaywallPopup/UpgradePopupContext";
import CircularProgress from "@/components/CircularProgress";
import HelpIconWithMenu from "./components/HelpIconWithMenu";
import { useGlobalState } from "@/experimental/useGlobalState";
import { dynamicKeyValueStore } from "@/utils/dynamicKeyValueStore";
import router from "next/router";
import { useLocalStorageSWR } from "@knowt/syncing/hooks/swr/localStorage";

export const SIDEBAR_EXPANDED_WIDTH = "var(--sidebar-expanded-width)";
export const SIDEBAR_COLLAPSED_WIDTH = "var(--sidebar-collapsed-width)";
export const WIDTH_ANIMATION = "width 200ms cubic-bezier(0.4, 0, 0.2, 1)";

export const useGlobalSidebarToggler = (initialValue = true) => {
    const { user } = useCurrentUser();
    const [isExpanded, setIsExpanded_] = useGlobalState("sidebarIsExpanded", initialValue);

    const debouncedUpdateUserInfo = useDebouncedCallback(async (value: boolean) => {
        if (user) await updateUserInfo({ isSidebarExpanded: value });
    }, 10_000);

    const setIsExpanded = useCallback(
        (value: boolean) => {
            setIsExpanded_(value);
            debouncedUpdateUserInfo(value);
        },
        [setIsExpanded_]
    );

    return { isExpanded, setIsExpanded };
};

const useSidebarInitialBehavior = ({ serverUser }) => {
    const pathname = usePathname();
    const { user, loginInProgress } = useCurrentUser({ fallbackData: serverUser });
    const { isExpanded: _isExpanded, setIsExpanded } = useGlobalSidebarToggler(
        user?.isSidebarExpanded ?? pathname === "/home"
    );

    useEffect(() => {
        if (!loginInProgress) {
            setIsExpanded(user?.isSidebarExpanded ?? pathname === "/home");
        }
    }, [loginInProgress]);

    useEffect(() => {
        setIsExpanded(user?.isSidebarExpanded ?? pathname === "/home");
    }, [pathname]);
};

const Sidebar = ({
    containerStyle,
    serverUser,
    forceCollapsed = false,
    footerAds = false,
}: {
    containerStyle?: React.CSSProperties;
    serverUser?: LocalUser;
    forceCollapsed?: boolean;
    footerAds?: boolean;
}) => {
    const { smDown, mdDown } = useBreakPoints();
    const pathname = usePathname();
    const { getFlowProgress, showChecklist } = useFlow();
    const { data: isHoverEnabled } = useLocalStorageSWR("hover-enabled", undefined, {
        default: true,
    });

    const { user, isAdmin, isTeacher, canShowAds, isLoading, subscriptionType } = useCurrentUser({
        fallbackData: serverUser,
    });
    useSidebarInitialBehavior({ serverUser });

    // const sidebarRef = useRef<HTMLDivElement>(null);

    const flowId = isTeacher ? TEACHER_ONBOARDING.id : STUDENT_ONBOARDING.id;
    const flowProgress = getFlowProgress(flowId);

    const openUpgradePopup = useUpgradePopupContextSelector(s => s.openUpgradePopup);

    const { isExpanded: isExpandedPermanent, setIsExpanded: setIsExpandedPermanent } = useGlobalSidebarToggler(
        user?.isSidebarExpanded ?? pathname === "/home"
    );

    const [isExpanded_, setIsExpanded] = useState(false);

    const handleMouseEnter = useCallback(() => {
        if (!isHoverEnabled) return;
        setIsExpanded(true);
    }, [isHoverEnabled]);

    const handleMouseLeave = useCallback(() => {
        if (!isHoverEnabled) return;
        setIsExpanded(false);
    }, [isHoverEnabled]);

    const isExpanded = forceCollapsed ? false : isExpandedPermanent || isExpanded_;

    const isSidebarVisible = !!user;
    const bottomPadding = footerAds && canShowAds ? "90px" : "0";

    useLayoutEffect(() => {
        if (!isLoading && !user) {
            setIsExpanded(false);
        }
    }, [user, isLoading]);

    const isSubscriptionCancelling = !!user?.subscriptions?.[0].cancel_at_period_end;

    if (!isSidebarVisible) {
        return null;
    }

    const getSidebarHighlight = ({ pageTab }: { pageTab: SidebarTab | undefined }) =>
        getSidebarHighlight_({ username: user?.username ?? "", pageTab, pathname, isExpanded });

    const getSidebarTabHref = ({ pageTab }: { pageTab: SidebarTab }) =>
        getSidebarTabHref_({ username: user?.username ?? "", pageTab, org: user?.org ?? "" });

    const LIBRARY_SECTIONS = (
        <>
            {/* Library Section */}
            <SidebarDivider name={isExpanded ? "Library" : undefined} isExpanded={isExpanded ?? false} />
            <SidebarSection>
                {[
                    { tab: SidebarTab.HOME, icon: House, label: "Home" },
                    { tab: SidebarTab.FILES, icon: Library, label: isTeacher ? "Materials" : "Files" },
                    { tab: SidebarTab.SAVED, icon: Bookmark, label: "Saved" },
                    { tab: SidebarTab.TRASH, icon: Trash2, label: "Trash" },
                ].map(({ tab, icon, label }) => (
                    <SidebarItemBtn
                        isExpanded={!!isExpanded}
                        label={label}
                        Icon={icon}
                        key={label}
                        id={`sidebar-tab-${tab}`}
                        tooltip={!isExpanded ? label : undefined}
                        sx={{ ...getSidebarHighlight({ pageTab: tab }) }}
                        component={LinkWrapper}
                        href={getSidebarTabHref({ pageTab: tab })}
                    />
                ))}
            </SidebarSection>
        </>
    );

    const CLASSES_SECTIONS = (
        <>
            {/* Classes Section */}
            <SidebarDivider name={isExpanded ? "Classes" : undefined} isExpanded={isExpanded} />
            <SidebarSection>
                <SidebarItemBtn
                    id={`sidebar-tab-${SidebarTab.CLASSES}`}
                    isExpanded={!!isExpanded}
                    label={"Classes"}
                    Icon={Presentation}
                    tooltip={!isExpanded ? "Classes" : undefined}
                    sx={{ ...getSidebarHighlight({ pageTab: SidebarTab.CLASSES }) }}
                    component={LinkWrapper}
                    href={getSidebarTabHref({ pageTab: SidebarTab.CLASSES })}
                />
                {isTeacher && (
                    <SidebarItemBtn
                        id={`sidebar-tab-${SidebarTab.AI_TOOLS}`}
                        isExpanded={!!isExpanded}
                        label={"AI Tools"}
                        Icon={School}
                        tooltip={!isExpanded ? "AI Tools" : undefined}
                        sx={{ ...getSidebarHighlight({ pageTab: SidebarTab.AI_TOOLS }) }}
                        component={LinkWrapper}
                        href={getSidebarTabHref({ pageTab: SidebarTab.AI_TOOLS })}
                    />
                )}
            </SidebarSection>
        </>
    );

    const ADMIN_SECTION = (
        <>
            {/* Classes Section */}
            <SidebarDivider name={isExpanded ? "Admin" : undefined} isExpanded={isExpanded} />
            <SidebarSection>
                <SidebarItemBtn
                    isExpanded={!!isExpanded}
                    label={"Admin Dashboard"}
                    Icon={Shield}
                    tooltip={!isExpanded ? "Admin Dashboard" : undefined}
                    sx={{ ...getSidebarHighlight({ pageTab: SidebarTab.ADMIN_DASHBOARD }) }}
                    component={LinkWrapper}
                    href={getSidebarTabHref({ pageTab: SidebarTab.ADMIN_DASHBOARD })}
                />
            </SidebarSection>
        </>
    );

    return (
        <>
            <FlexColumn
                as="nav"
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                className={clsx("hide-scrollbar", {
                    [br.smDownDisplayNone]: !isExpandedPermanent,
                })}
                style={{
                    gap: "0.5rem",
                    backgroundColor: themeColors.neutralWhite,
                    width:
                        !forceCollapsed && (isExpandedPermanent || (isExpanded && !smDown))
                            ? SIDEBAR_EXPANDED_WIDTH
                            : SIDEBAR_COLLAPSED_WIDTH,
                    paddingBottom: bottomPadding,
                    transition: WIDTH_ANIMATION,
                    zIndex: 1201,
                    position: "fixed",
                    top: "0",
                    margin: "0",
                    height: "100vh",
                    ...containerStyle,
                }}>
                {/* SCROLLABLE TOP SECTION */}
                <FlexColumn
                    className="scrollbar-thin"
                    style={{ padding: "1.5rem", gap: "0.5rem", flex: 1, overflow: "hidden auto" }}>
                    <SidebarSection style={{ marginBottom: spacing.XS, justifyContent: "space-between" }}>
                        <KnowtLogo
                            isExpanded={!!isExpanded}
                            onClick={async () => {
                                const defaultTab = ((await dynamicKeyValueStore.getOnlyUseCookies("default-tab")) ??
                                    SidebarTab.HOME) as SidebarTab;
                                router.push(getSidebarTabHref({ pageTab: defaultTab }));
                            }}
                            style={{
                                marginLeft: isExpanded ? "1rem" : "0.65rem",
                                transition: "margin-left 200ms ease-in-out",
                            }}
                        />

                        <SidebarToggler
                            isVisible={isExpanded || !isHoverEnabled}
                            isExpanded={!!isExpandedPermanent}
                            onClick={() => setIsExpandedPermanent(!isExpandedPermanent)}
                            sx={{ position: "absolute", top: 17, right: isHoverEnabled ? "1.2rem" : "-1.2rem" }}
                        />
                    </SidebarSection>
                    {isAdmin && ADMIN_SECTION}
                    {isTeacher ? (
                        <>
                            {CLASSES_SECTIONS}
                            {LIBRARY_SECTIONS}
                        </>
                    ) : (
                        <>
                            {LIBRARY_SECTIONS}
                            {CLASSES_SECTIONS}
                        </>
                    )}
                    {/* BROWSE SECTION */}
                    <SidebarDivider name={isExpanded ? "Browse" : undefined} isExpanded={isExpanded} />
                    <SidebarSection>
                        {[
                            { href: "/explore", icon: Telescope, label: "Explore", tab: SidebarTab.EXPLORE },
                            { href: "/exams", icon: BookCheck, label: "Exams", tab: SidebarTab.EXAMS },
                            { href: "/store", icon: ShoppingBag, label: "Store", tab: SidebarTab.STORE },
                        ].map(({ href, icon, label, tab }) => (
                            <SidebarItemBtn
                                key={label}
                                id={`sidebar-tab-${tab}`}
                                sx={{ ...getSidebarHighlight({ pageTab: tab }) }}
                                tooltip={!isExpanded ? label : undefined}
                                component={LinkWrapper}
                                href={href}
                                Icon={icon}
                                label={label}
                                isExpanded={!!isExpanded}
                            />
                        ))}
                    </SidebarSection>

                    {!isTeacher && (
                        <>
                            <SidebarDivider name={isExpanded ? "AI Tools" : undefined} isExpanded={isExpanded} />
                            <SidebarSection className="secondaryText2">
                                {[
                                    {
                                        href: "/ai-pdf-summarizer",
                                        img: getSidebarImage({ tab: SidebarTab.AI_PDF_SUMMARIZER }),
                                        label: "AI PDF Summarizer",
                                        tab: SidebarTab.AI_PDF_SUMMARIZER,
                                    },
                                    {
                                        href: "/ai-video-summarizer",
                                        img: getSidebarImage({ tab: SidebarTab.AI_VIDEO_SUMMARIZER }),
                                        label: "AI Video Summarizer",
                                        tab: SidebarTab.AI_VIDEO_SUMMARIZER,
                                    },
                                    {
                                        href: "/ai-lecture-note-taker",
                                        img: getSidebarImage({ tab: SidebarTab.AI_LECTURE_NOTE_TAKER }),
                                        label: "AI Lecture Note Taker",
                                        tab: SidebarTab.AI_LECTURE_NOTE_TAKER,
                                    },
                                ].map(({ href, img, label, tab }) => (
                                    <SidebarItemBtn
                                        key={label}
                                        id={`sidebar-tab-${tab}`}
                                        sx={{ ...getSidebarHighlight({ pageTab: tab }) }}
                                        tooltip={!isExpanded ? label : undefined}
                                        component={LinkWrapper}
                                        href={href}
                                        CustomIcon={<Image src={img} alt={label} width={20} height={20} />}
                                        label={label}
                                        isExpanded={!!isExpanded}
                                    />
                                ))}
                            </SidebarSection>
                        </>
                    )}
                </FlexColumn>

                {/* FIXED BOTTOM SECTION */}
                <FlexColumn
                    style={{ padding: "1.5rem", gap: "0.5rem", borderTop: `1px solid ${themeColors.neutral1}` }}>
                    <SidebarSection>
                        {!flowProgress.isCompleted && (
                            <SidebarItemBtn
                                id="show-pointer-checklist"
                                isExpanded={!!isExpanded}
                                label={"Get Started"}
                                component={"div"}
                                onClick={() => showChecklist(flowId)}
                                CustomIcon={
                                    <CircularProgress
                                        value={flowProgress.percentage}
                                        size={"2rem"}
                                        thickness={5}
                                        bgColor={themeColors.neutralBlack}
                                        color={themeColors.primary}
                                        disableAnimation
                                    />
                                }
                            />
                        )}

                        <SidebarItemBtn
                            isExpanded={!!isExpanded}
                            label={"Refer friends & earn"}
                            Icon={Gift}
                            key={"Refer friends & earn"}
                            id={`sidebar-tab-${SidebarTab.REFERRAL}`}
                            sx={{ ...getSidebarHighlight({ pageTab: SidebarTab.REFERRAL }) }}
                            component={LinkWrapper}
                            href={getSidebarTabHref({ pageTab: SidebarTab.REFERRAL })}
                        />

                        <HelpIconWithMenu key={"sidebar-helps"} footerAds={footerAds} isExpanded={!!isExpanded} />

                        <SidebarItemBtn
                            isExpanded={!!isExpanded}
                            label={"Settings"}
                            Icon={Settings}
                            key={"Settings"}
                            id={`sidebar-tab-${SidebarTab.SETTINGS}`}
                            sx={{ ...getSidebarHighlight({ pageTab: SidebarTab.SETTINGS }) }}
                            component={LinkWrapper}
                            href={"/settings"}
                        />

                        <SidebarItemBtn
                            isExpanded={!!isExpanded}
                            label={"My Account"}
                            component={"div"}
                            onClick={e => {
                                // NOTE: neat way to open the menu from `ProfileCircleWithMenu` programmatically
                                e.currentTarget.querySelector("button")?.click?.();
                            }}
                            CustomIcon={
                                <ProfileCircleWithMenu
                                    isSidebarExpanded={!!isExpanded}
                                    radius={"2.6rem"}
                                    pictureUrl={user?.pictureUrl || ""}
                                    footerAds={footerAds}
                                />
                            }
                        />

                        {/* Upgrade Plan Button */}
                        <Mountable
                            mount={
                                !isSubscriptionCancelling &&
                                subscriptionType !== SubscriptionType.LIMITLESS &&
                                subscriptionType !== SubscriptionType.TEACHER_LIMITLESS
                            }>
                            <SidebarItemBtn
                                isExpanded={!!isExpanded}
                                label={"Upgrade Plan"}
                                Icon={Zap}
                                IconProps={{ fill: themeColors.pureBlack, color: themeColors.pureBlack }}
                                tooltip={!isExpanded ? "Upgrade Plan" : undefined}
                                sx={{
                                    "& #SidebarIcon": {
                                        backgroundColor: themeColors.primary3,
                                    },
                                }}
                                onClick={() => {
                                    openUpgradePopup({
                                        event: UpgradeEvent.GENERIC,
                                        context: {},
                                    });
                                }}
                            />
                        </Mountable>
                    </SidebarSection>
                </FlexColumn>
            </FlexColumn>

            {/* DUMMY PLACEHOLDER. NEEDED! */}
            {/* this is template so that we preserve the width for the sidebar since our sidebar's position is `fixed` */}
            <div
                className={br.smDownDisplayNone}
                style={{
                    width: (() => {
                        if (mdDown || !isExpandedPermanent || forceCollapsed) {
                            return SIDEBAR_COLLAPSED_WIDTH;
                        }
                        return SIDEBAR_EXPANDED_WIDTH;
                    })(),
                    height: "100%",
                    margin: "0",
                    transition: WIDTH_ANIMATION,
                }}
            />
        </>
    );
};

export default Sidebar;
