"use client";

import CircularRectTextButton from "@/components/CircularButton/styled/CircularRectTextButton";
import { FlexColumn, FlexRowAlignCenter } from "@/components/Flex";
import { ASSETS_URL } from "@/config/deployConstants";
import TaggingAutocomplete from "@/features/Tagging/components/styled/TaggingAutocomplete";
import { spacing } from "@/utils/spacing";
import { themeColors } from "@/utils/themeColors";
import { Organization } from "@knowt/syncing/graphql/schema";
import { useSchools } from "@knowt/syncing/hooks/schools/useSchool";
import { updateUserInfo } from "@knowt/syncing/hooks/user/utils";
import Image from "next/image";
import { Dispatch, useState } from "react";
import toast from "react-hot-toast";
import InitialSetupContainer from "../styled/IntialSetupContainer";

const OrganizationSchoolInput = ({
    organization,
    setSelectedSchool,
}: {
    organization: Organization;
    setSelectedSchool: Dispatch<React.SetStateAction<string | undefined>>;
}) => {
    const { schools } = useSchools({ organization });

    return (
        <TaggingAutocomplete
            useAutocompleteProps={{
                options:
                    schools?.map(school => ({
                        label: school.name,
                        value: school.schoolId,
                    })) || [],
                onChange: (_e, value) => {
                    setSelectedSchool(value?.value.toString());
                },
            }}
            inputProps={{
                placeholder: "Search your school",
                id: "school",
                sx: { backgroundColor: themeColors.neutralWhite },
            }}
            liHeaderProps={{
                sx: {
                    backgroundColor: themeColors.neutralWhite,
                    color: themeColors.neutralBlack,
                    fontWeight: "bold",
                },
            }}
        />
    );
};

const AccountSchoolSelection = ({ onNext, organization }: { onNext: () => void; organization?: Organization }) => {
    const [selectedSchool, setSelectedSchool] = useState<string | undefined>(undefined);

    const handleSchoolNotFound = async () => {
        await updateUserInfo({ schoolId: "NONE" });
        toast.success("You can always add your school later");
        onNext();
    };

    const handleNext = async () => {
        if (!selectedSchool) {
            return toast.error("Please select a school to continue");
        }

        await updateUserInfo({ schoolId: selectedSchool });
        onNext();
    };

    return (
        <InitialSetupContainer style={{ textAlign: "center" }}>
            <Image src={`${ASSETS_URL}/images/school-icon.svg`} alt={"Search your school"} width={124} height={124} />
            <FlexColumn style={{ margin: "4rem 0 2.5rem 0" }}>
                <h1 className="heading4">Your school might have an upgraded account ready for you!</h1>
                <span className="body2">
                    This information will not be publicly displayed. We’re asking so that if your school has purchased a
                    subscription you’ll receive it automatically.
                </span>
            </FlexColumn>
            <FlexColumn style={{ marginBottom: spacing.LG_2, width: "100%" }}>
                <FlexColumn style={{ gap: spacing.XS, width: "100%", maxWidth: "50rem", textAlign: "start" }}>
                    <span className="bodyBold2">School</span>
                    {organization && (
                        <OrganizationSchoolInput organization={organization} setSelectedSchool={setSelectedSchool} />
                    )}
                </FlexColumn>
            </FlexColumn>
            <FlexRowAlignCenter style={{ gap: spacing.XS_2, justifyContent: "flex-end", width: "100%" }}>
                <CircularRectTextButton
                    onClick={handleSchoolNotFound}
                    style={{
                        border: `1px solid ${themeColors.neutralBlack}`,
                        padding: `${spacing.XS_2} ${spacing.MD}`,
                    }}>
                    {"I don't see my school!"}
                </CircularRectTextButton>
                <CircularRectTextButton
                    onClick={handleNext}
                    style={{
                        backgroundColor: themeColors.primary,
                        color: themeColors.primaryDark,
                        padding: `${spacing.XS_2} ${spacing.MD}`,
                    }}>
                    Next
                </CircularRectTextButton>
            </FlexRowAlignCenter>
        </InitialSetupContainer>
    );
};

export default AccountSchoolSelection;
